import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import { store, history } from './app/store';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import { msalConfig } from './authConfig'; 
import Search from './features/search/search';
import EtgAccess from './features/etgAccess/etgAccess';
import PatientInfo from './features/patientInfo/patientInfo';
import TablesAndFigures from './features/tables-and-figures/tables-and-figures';
import Calculator from './features/calculator/calculator';
import ContactUs from './features/contact-us/ContactUs';
import Import from './features/import/Import';
import GenAI from './features/genai/genai';
import './css/app.min.css';
import './css/appmediaqueries.min.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { CallbackPage } from './features/etgAccess/CallbackPage';

const appRoot = "root-dart";

const msalInstance = new PublicClientApplication(msalConfig);

console.log("Dart_build<");
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store()}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={EtgAccess} />
            <Route path="/assets" component={EtgAccess} />
            <Route path="/etgAccess" component={EtgAccess} />
            <Route path="/openathens-callback" component={CallbackPage} />
            <Route path="/viewTopic" component={App} />
            <Route path="/quicklinks" component={App} />
            <Route path="/topicTeaser" component={App} />
            <Route path="/searchAction" component={Search} />
            <Route path="/patientInfo" component={PatientInfo} />
            <Route path="/tables-and-figures" component={TablesAndFigures} />
            <Route path="/calculator" component={Calculator} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/import" component={Import} />
            <Route path="/genai" component={GenAI} />
            <Route component={EtgAccess} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById(appRoot)
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if(localStorage.getItem('processenv') === 'dev') {
  console.log("Environment variables are: ", process.env);
};